/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
//import '@ui/styles/globals.css';
import '@styles/globals.css';
import '@styles/Homepage/style.css';
import '@styles/Products/style.css';
import 'aos/dist/aos.css';
import { getLayoutConfig } from '@components/Layout/LayoutConfiguration';
import dynamic from 'next/dynamic';
import { adminPaths } from '@helpers/constants';
import Aos from 'aos';
import { useEffect } from 'react';
const ToastContainer = dynamic(() =>
    import('react-toastify').then((mod) => mod.ToastContainer)
);
const InvestorContextProvider = dynamic(() =>
    import('@context/InvestorContextProvider')
);
const WithAuth = dynamic(() => import('@components/Auth/WithAuth'), {
    ssr: true,
    csr: true,
});

function MyApp({ Component, pageProps, router }) {
    let layoutConfig = getLayoutConfig(router.pathname, router.query);
    const { Layout: FetchedLayout, overrides } = layoutConfig;

    const DynamicFormio = dynamic(() => import('@components/formio/formio'), {
        ssr: false,
    });

    useEffect(() => {
        Aos.init({ duration: 800, once: false });
    }, []);

    return (
        <>
            <WithAuth router={router}>
                {adminPaths.includes(layoutConfig?.path) ? (
                    <FetchedLayout
                        children={<Component {...pageProps} />}
                        {...overrides}
                    />
                ) : (
                    <InvestorContextProvider>
                        <FetchedLayout
                            children={<Component {...pageProps} />}
                            {...overrides}
                        />
                    </InvestorContextProvider>
                )}
            </WithAuth>

            <ToastContainer />
            <DynamicFormio></DynamicFormio>
        </>
    );
}

export default MyApp;
