const typography = {
    '.font-heading': {
        'letter-spacing': '0',
    },
    '.font-paragraph': {
        'letter-spacing': '0.025em',
    },
    '.d1': {
        'font-size': '4.5rem',
        'line-height': '6rem',
        'font-style': 'normal',
        '@apply font-heading': {},
    },
    '.d1-regular': {
        '@apply d1 font-normal': {},
    },
    '.d1-semibold': {
        '@apply d1 font-semibold': {},
    },
    '.d1-bold': {
        '@apply d1 font-bold': {},
    },
    '.d2': {
        'font-size': '3.75rem',
        'line-height': '5rem',
        'font-style': 'normal',
        '@apply font-heading': {},
    },
    '.d2-regular': {
        '@apply d2 font-normal': {},
    },
    '.d2-semibold': {
        '@apply d2 font-semibold': {},
    },
    '.d2-bold': {
        '@apply d2 font-bold': {},
    },
    '.d3': {
        'font-size': '3.5rem',
        'line-height': '4.5rem',
        'font-style': 'normal',
        '@apply font-heading': {},
    },
    '.d3-regular': {
        '@apply d3 font-normal': {},
    },
    '.d3-semibold': {
        '@apply d3 font-semibold': {},
    },
    '.d3-bold': {
        '@apply d3 font-bold': {},
    },
    '.h1': {
        'font-size': '3rem',
        'line-height': '4rem',
        'font-style': 'normal',
        '@apply font-heading': {},
    },
    '.h1-regular': {
        '@apply h1 font-normal': {},
    },
    '.h1-semibold': {
        '@apply h1 font-semibold': {},
    },
    '.h1-bold': {
        '@apply h1 font-bold': {},
    },
    '.h2': {
        'font-size': '2.5rem',
        'line-height': '3.5rem',
        'font-style': 'normal',
        '@apply font-heading': {},
    },
    '.h2-regular': {
        '@apply h2 font-normal': {},
    },
    '.h2-semibold': {
        '@apply h2 font-semibold': {},
    },
    '.h2-bold': {
        '@apply h2 font-bold': {},
    },
    '.h3': {
        'font-size': '2rem',
        'line-height': '2.375rem',
        'font-style': 'normal',
        '@apply font-heading': {},
    },
    '.h3-regular': {
        '@apply h3 font-normal': {},
    },
    '.h3-semibold': {
        '@apply h3 font-semibold': {},
    },
    '.h3-bold': {
        '@apply h3 font-bold': {},
    },
    '.h4': {
        'font-size': '1.5rem',
        'line-height': '2rem',
        'font-style': 'normal',
        '@apply font-heading': {},
    },
    '.h4-regular': {
        '@apply h4 font-normal': {},
    },
    '.h4-semibold': {
        '@apply h4 font-semibold': {},
    },
    '.h4-bold': {
        '@apply h4 font-bold': {},
    },
    '.h5': {
        'font-size': '1.25rem',
        'line-height': '1.75rem',
        'font-style': 'normal',
        '@apply font-heading': {},
    },
    '.h5-regular': {
        '@apply h5 font-normal': {},
    },
    '.h5-semibold': {
        '@apply h5 font-semibold': {},
    },
    '.h5-bold': {
        '@apply h5 font-bold': {},
    },
    '.h6': {
        'font-size': '1rem',
        'line-height': '1.25rem',
        'font-style': 'normal',
        '@apply font-heading': {},
    },
    '.h6-regular': {
        '@apply h6 font-normal': {},
    },
    '.h6-semibold': {
        '@apply h6 font-semibold': {},
    },
    '.h6-bold': {
        '@apply h6 font-bold': {},
    },
    '.p1': {
        'font-size': '1.5rem',
        'line-height': '2.5rem',
        'font-style': 'normal',
        '@apply font-paragraph': {},
    },
    '.p1-regular': {
        '@apply p1 font-normal': {},
    },
    '.p1-medium': {
        '@apply p1 font-medium': {},
    },
    '.p1-semibold': {
        '@apply p1 font-semibold': {},
    },
    '.p1-bold': {
        '@apply p1 font-bold': {},
    },
    '.p1-underline': {
        '@apply p1-medium underline': {},
    },
    '.p2': {
        'font-size': '1.25rem',
        'line-height': '2rem',
        'font-style': 'normal',
        '@apply font-paragraph': {},
    },
    '.p2-regular': {
        '@apply p2 font-normal': {},
    },
    '.p2-medium': {
        '@apply p2 font-medium': {},
    },
    '.p2-semibold': {
        '@apply p2 font-semibold': {},
    },
    '.p2-bold': {
        '@apply p2 font-bold': {},
    },
    '.p2-underline': {
        '@apply p2-medium underline': {},
    },
    '.p3': {
        'font-size': '1.125rem',
        'line-height': '1.75rem',
        'font-style': 'normal',
        '@apply font-paragraph': {},
    },
    '.p3-regular': {
        '@apply p3 font-normal': {},
    },
    '.p3-medium': {
        '@apply p3 font-medium': {},
    },
    '.p3-semibold': {
        '@apply p3 font-semibold': {},
    },
    '.p3-bold': {
        '@apply p3 font-bold': {},
    },
    '.p3-underline': {
        '@apply p3-medium underline': {},
    },
    '.p4': {
        'font-size': '1rem',
        'line-height': '1.5rem',
        'font-style': 'normal',
        '@apply font-paragraph': {},
    },
    '.p4-regular': {
        '@apply p4 font-normal': {},
    },
    '.p4-medium': {
        '@apply p4 font-medium': {},
    },
    '.p4-semibold': {
        '@apply p4 font-semibold': {},
    },
    '.p4-bold': {
        '@apply p4 font-bold': {},
    },
    '.p4-underline': {
        '@apply p4-medium underline': {},
    },
    '.p5': {
        'font-size': '0.875rem',
        'line-height': '1.25rem',
        'font-style': 'normal',
        '@apply font-paragraph': {},
    },
    '.p5-regular': {
        '@apply p5 font-normal': {},
    },
    '.p5-medium': {
        '@apply p5 font-medium': {},
    },
    '.p5-semibold': {
        '@apply p5 font-semibold': {},
    },
    '.p5-bold': {
        '@apply p5 font-bold': {},
    },
    '.p5-underline': {
        '@apply p5-medium underline': {},
    },
    '.p6': {
        'font-size': '0.75rem',
        'line-height': '1rem',
        'font-style': 'normal',
        '@apply font-paragraph': {},
    },
    '.p6-regular': {
        '@apply p6 font-normal': {},
    },
    '.p6-medium': {
        '@apply p6 font-medium': {},
    },
    '.p6-semibold': {
        '@apply p6 font-semibold': {},
    },
    '.p6-bold': {
        '@apply p6 font-bold': {},
    },
    '.p6-underline': {
        '@apply p6-medium underline': {},
    },
    '.p7': {
        'font-size': '0.625rem',
        'line-height': '0.875rem',
        'font-style': 'normal',
        '@apply font-paragraph': {},
    },
    '.p7-regular': {
        '@apply p7 font-normal': {},
    },
    '.p7-medium': {
        '@apply p7 font-medium': {},
    },
    '.p7-semibold': {
        '@apply p7 font-semibold': {},
    },
    '.p7-bold': {
        '@apply p7 font-bold': {},
    },
    '.p7-underline': {
        '@apply p7-medium underline': {},
    },
};

export default typography;