import { FinancialInstrumentTypes } from '../financialInstrumentType/financial-instrument-types';
import { OpportunityTypes } from './opportunity-types';

export const getAllOpportunityTypes = () => Object.values(OpportunityTypes);
export const getInvoiceDiscountingOpportunity = () => OpportunityTypes.INVOICE_DISCOUNTING;
export const getAssetBackedLeasingOpportunity = () => OpportunityTypes.ASSET_BACKED_LEASING;
export const getCorporateDebtOpportunity = () => OpportunityTypes.CORPORATE_DEBT;
export const getVentureDebtOpportunity = () => OpportunityTypes.VENTURE_DEBT;
export const getTreasuryBillOpportunity = () => OpportunityTypes.TREASURY_BILL;

export const getDebtOpportunityTypes = () => [getCorporateDebtOpportunity(), getVentureDebtOpportunity()];
export const getUnitsOpportunities = () => [getCorporateDebtOpportunity(), getVentureDebtOpportunity(), getTreasuryBillOpportunity()];
export const getNonUnitsOpportunities = () => [getInvoiceDiscountingOpportunity(), getAssetBackedLeasingOpportunity()];

export const isInvoiceDiscountingOpportunity = (opportunityType) => opportunityType === getInvoiceDiscountingOpportunity();
export const isAssetBackedLeasingOpportunity = (opportunityType) => opportunityType === getAssetBackedLeasingOpportunity();
export const isCorporateDebtOpportunity = (opportunityType) => opportunityType === getCorporateDebtOpportunity();
export const isVentureDebtOpportunity = (opportunityType) => opportunityType === getVentureDebtOpportunity();
export const isTreasuryBillOpportunity = (opportunityType) => opportunityType === getTreasuryBillOpportunity();
export const isPassThroughCertificateOpportunity = (opportunityType, financialInstrument) => opportunityType === getCorporateDebtOpportunity() && financialInstrument === FinancialInstrumentTypes.PTC;

export const isOpportunityDebtType = (opportunityType) => {
    const opportunityDebtType = getDebtOpportunityTypes();
    return opportunityDebtType.includes(opportunityType);
};
export const isNonUnitsOpportunity = (opportunityType) => {
    const nonUnitOpportunities = getNonUnitsOpportunities();
    return nonUnitOpportunities.includes(opportunityType);
};
export const isUnitsOpportunity = (opportunityType) => {
    const unitOpportunities = getUnitsOpportunities();
    return unitOpportunities.includes(opportunityType);
};

export const calculateTds = (opportunity, investor) => {
    const { type, instrument, taxAtSource, taxAtSourceNonIndividual } = opportunity;
    if (investor) {
        const { investorType } = investor;

        const isNonIndividual = investorType !== "Individual";
        const isPtc = isPassThroughCertificateOpportunity(type, instrument);

        if (isPtc && isNonIndividual) return taxAtSourceNonIndividual;
    }
    return taxAtSource;
};