module.exports = {
    basicWhite: '#FFFFFF',
    basicBlack: '#000000',
    primary: {
        10: '#F7F7FD',
        20: '#ECECF9',
        30: '#E4E3F7',
        40: '#C9C8EF',
        50: '#B1B0E8',
        100: '#8B89DC',
        200: '#6461D1',
        300: '#3D3AC5',
        400: '#312E9E',
        500: '#26247B',
        600: '#1A1853',
        700: '#14123F',
    },
    secondary: {
        10: '#F6FFFB',
        20: '#EAFFF4',
        50: '#DEF7EA',
        100: '#B6EDD0',
        200: '#85E0B1',
        300: '#54D492',
        400: '#1FBD6C',
        500: '#00A651',
        600: '#077E41',
        700: '#006632',
        800: '#004D25',
        900: '#003319',
    },
    altSmart: {
        100: '#CCF3FE',
        500: '#00BCEF',
    },
    altArmour: {
        100: '#CFE7FF',
        500: '#007EFF',
    },
    altBlu: {
        100: '#DAD8FE',
        500: '#564DFF',
    },
    altPack: {
        100: '#F0E1FF',
        500: '#8A0DFF',
    },
    altWings: {
        100: '#F7E2FF',
        500: '#710099',
    },
    gray: {
        50: '#F9F9F9',
        100: '#F4F4F4',
        200: '#E5E5E5',
        300: '#D1D1D1',
        400: '#9C9C9C',
        500: '#676767',
        600: '#4B4B4B',
        700: '#373737',
        800: '#1F1F1F',
        900: '#1C1C1C',
    },
    purple: { //to be removed
        50: '#D9D9FF',
        200: '#7575F7',
        300: '#4D4DF1',
        400: '#3D3DC5',
    },
    green: { //to be removed
        50: '#F0FFFB',
        100: '#C9FDEE',
        400: '#00CF8D',
    },
    pink: {
        50: '#FCEDF3',
        70: '#FFE6F0',
        100: '#F8D3E1',
        200: '#F3B4CB',
        800: '#FF666C', //to be removed
    },
    periwinkle: {
        50: '#F2F2FC',//to be removed
    },
    tiffanyBlue: {
        50: '#E4F6F5',//to be removed
    },
    steelBlue: {
        50: '#E7EAF3',
        100: '#D2D7E9',
        200: '#B2BBDA',
    },
    skin: {
        50: '#FCF3F3',
        100: '#F8E3E3',
        200: '#F2CECE',
    },
    skyBlue: {
        50: '#E6F3FE',
        100: '#CEE6FD',
        200: '#ABD5FC',
    },
    mauve: {
        50: '#F4EAFB',
        100: '#E5CCF5',
        200: '#D3AAEE'
    },
    yellow: {
        500: '#EAB308',
    },
    leafGreen: {
        100: '#D6F3D9',
        300: '#8BF096',
        600: '#16A34A',
    },
    bgColor: {
        50: '#F7F7FE',
        100: '#ECECF7',
    },
    links: {
        600: '#002E5E',
        800: '#001F3F',
    },
    semantic: {
        'info-light': '#E5F5FC',
        'info-base': '#017AAD',
        'success-light': '#EAF3EB',
        'success-base': '#289A3F',
        'warning-light': '#FFEFE4',
        'warning-base': '#F37921',
        'error-light': '#FCEAEA',
        'error-base': '#E05C5C',
        'yellow-light': '#FCF2D4',
        'yellow-base': '#DBA600',
        'olive-light': '#EFF1D0',
        'olive-base': '#A1A329',
    },
    transparent: 'transparent',
    darkOrange: '#5E3E03',
    lightYellow: '#FFFDF1',
    darkYellow: '#FFE4B2',
    special: {
        'gold': '#D7BC5F',
        'gold-light': '#FEF7E7',
    }
};
