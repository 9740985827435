import colorConstants from '../jiraaf-ui/helpers/color-constants';

module.exports = {
    'open-opportunity-sticky-actions-sm': `linear-gradient(180deg, transparent 41%, ${colorConstants?.gray?.[50]} 13%)`,
    'closed-opportunity-sticky-actions-sm': `linear-gradient(180deg, transparent 22%, ${colorConstants?.gray?.[50]} 13%)`,
    'opportunity-sticky-actions': `linear-gradient(180deg, transparent 50%, ${colorConstants?.gray?.[50]} 50%)`,
    'referral-gradient-bg-primary': 'url(/images/referral-gradient-bg.svg)',
    'rebranding-ticker': `linear-gradient(90deg, #26247B 0.06%, #00A651 152.83%)`,
    'id-linear-gradient-banner':
        'linear-gradient(66deg, rgba(0, 188, 239, 0.40) 0%, rgba(0, 126, 255, 0.40) 33%, rgba(86, 77, 255, 0.40) 67%, rgba(108, 20, 232, 0.40) 100%)',
    'id-risk-gradient': 'linear-gradient(180deg, #F3F3FF 0%, #FFF 100.01%)',
    'gradient-primary-25': `linear-gradient(to bottom, ${colorConstants?.primary?.[500]} 188px, transparent 188px)`,
    'combo-deal-opportunity':
        ' linear-gradient(90deg, #3D3AC5 0.19%, #B1B0E8 200.56%)',
    'festive-opportunity-card':
        'linear-gradient(180deg, #FFFCF3 0.13%, #FFF 42.13%)',
    'festive-opportunity-card-irr':
        'linear-gradient(270deg, #FFFBE4 0%, rgba(255, 251, 228, 0.00) 100%)',
    'festive-opportunity-card-border-gradient':
        'linear-gradient(143deg, #D7BC5F -1.67%, #FFF2C6 23.45%, #D9AF1B 48.05%, #FFE99B 72.65%, #B49528 98.82%)',
    'admin-opportunity-card-border-gradient':
        'linear-gradient(180.2deg, #1E2130 6.8%, #4A626E 131%)',
    'signup-page-festive-card-mobile-and-tab':
        'linear-gradient(180deg, #FFFCF3 0%, #FFF 56.39%)',
    'altgraaf-banner-bg':
        ' linear-gradient(180deg, #D3EFFA 61.17%, rgba(211, 239, 250, 0.00) 100%)',
    'signup-festive-bg-md':
        'linear-gradient(270deg, rgba(128, 126, 211, 0.17) 0.84%, rgba(209, 208, 255, 0.08) 58.85%, #C6C6EA 75.7%, #26247B 92.56%)',
    'signup-festive-bg-mobile':
        'radial-gradient(2105.42% 98.86% at 84.86% 98.86%, rgba(255, 255, 255, 0.08) 0%, #26247B 72.04%)',
    'green-horizontal':
        'linear-gradient(90deg, #B6EDD0 0%, #DEF7EA 50%, #B6EDD0 100%)',
    'download-app-card-bg':
        'linear-gradient(92deg, #F0D094 -9.26%, #FFFBF2 45.98%, #F0D094 100.76%)',
    'footer-app':
        'linear-gradient(121deg, rgba(255, 255, 255, 0.24) 0%, rgba(238, 238, 238, 0.22) 18.13%, rgba(224, 224, 224, 0.20) 27.22%, rgba(212, 212, 212, 0.18) 41.16%, rgba(207, 207, 207, 0.16) 51.19%, rgba(202, 202, 202, 0.14) 61.76%, rgba(200, 200, 200, 0.12) 70.06%, rgba(196, 196, 196, 0.10) 77.39%, rgba(196, 196, 196, 0.08) 100%)',
    'altSmart-bg-50':
        'linear-gradient(90deg, #F0FAFC 0%, #CDF4FF 49.5%, #F0FAFC 100%)',
    'altArmour-bg-50':
        'linear-gradient(90deg, #F1F9FF 1.36%, #CBE5FF 49.24%, #F1F9FF 97.35%)',
    'altBlu-bg-50':
        'linear-gradient(90deg, #F5F7FF 1.36%, #D2DCFF 49.24%, #F5F7FF 97.35%)',
    'altPack-bg-50':
        'linear-gradient(90deg, #F8F6FF 0%, #E3D6FF 49.5%, #F8F6FF 100%)',
    'altWings-bg-50':
        'linear-gradient(90deg, #FCF2FF 0%, #F5D8FF 49.5%, #FCF2FF 100%)',
    'pink-bg-50':
        'linear-gradient(90deg, #FFF5F5 0%, #F8E2EB 50%, #FFF5F5 100%)',
    'altWings-linear50': 'linear-gradient(90deg, #FCF2FF 0%, #F5D8FF 100%)',
    'altPack-linear50': 'linear-gradient(90deg, #F8F6FF 0%, #E3D6FF 100%)',
    'altBlu-linear50': 'linear-gradient(90deg, #F5F7FF 1.14%, #D2DCFF 97.35%)',
    'altArmour-linear50':
        'linear-gradient(90deg, #F1F9FF 1.14%, #CBE5FF 97.35%)',
    'altSmart-linear50': 'linear-gradient(90deg, #F0FAFC 0%, #CDF4FF 100%)',
    'cd-linear50': 'linear-gradient(90deg, #FFF5F5 0%, #F8E2EB 100%)',
    'steel-blue-bg-50':
        'linear-gradient(90deg, #F5F6FA 0%, #DDE1EE 50%, #F5F6FA 100%)',
    'sky-blue-bg-50':
        'linear-gradient(90deg, #F0F8FF 0%, #CEE7FD 50%, #F0F7FF 100%)',
    'skin-bg-50':
        'linear-gradient(90deg, #FDF7F7 0%, #F7DEDE 50%, #FDF7F7 100%)',
    'brand-mobile-gradient':
        'url(/images/brands/mobile-brand-bg.webp), linear-gradient(to bottom, #04152D, #002D76)',
    'brand-desktop-gradient':
        'url(/images/brands/desktop-brand-bg.webp), linear-gradient(to bottom, #04152D, #002D76)',
    'webinar-banner-gradient': 'linear-gradient(180deg, #FFF 0%, #E9E8FF 100%)',
    'questionnaire-gradient': 'linear-gradient(180deg, #F6FFFB 0%, #FFF 100%)',
    'questionnaire-element':
        'linear-gradient(180deg, rgba(24, 75, 255, 0.00) 0%, #174AFF 100%)',
    'welcome-asset-card': 'linear-gradient(90deg, #F6FFFB 0%, #FFF 100%)',
    'welcome-asset-card-hover': 'linear-gradient(90deg, #E4E3F7 0%, #FFF 100%)',
    'welcome-asset-card-mobile':
        'linear-gradient(270deg, #EAFFF4 0%, #FFF 100%)',
    'home-page-banner': 'linear-gradient(180deg, #DADBF7 0%, #8C90FC 100%)',
    'signup-page-stats-bg': 'linear-gradient(45deg, #E9E8FF, #DCF4E8)',
    'home-page-banner-primary-bg':
        'linear-gradient(180deg, #EEFFF6 0%, #20FF8D 100%)',
    'home-page-banner-secondary-bg':
        'linear-gradient(180deg, #8C90FC 0%, #DADBF6 100%)',
    'home-page-primary-bg-sm':
        'linear-gradient(-45deg, #E5E5FB 10%, #ffffff 100%)',
    'home-page-primary-bg-md':
        'linear-gradient(135deg, #ffffff 35%, #DEF7EA 100%)',
    'home-page-secondary-bg-sm':
        'linear-gradient(-45deg, #ffffff80 33%, #DEF7EA 100%)',
    'home-page-secondary-bg-md':
        'linear-gradient(-45deg,#ffffff80 50%, #E5E5FB 100%)',
    'home-page-secondary-bg-xl':
        'linear-gradient(-45deg,#ffffff80 26%, #E5E5FB 100%)',
    'golden-horizontal-light': 'linear-gradient(90deg, #F3E2C2 , #FDF5DF 50%, #F3E2C2 100%)',
    'kyc-bg':
        'linear-gradient(90deg, #289A3F 0%, #289A3F 50%, #E5E5E5 50%, #E5E5E5 100%)',
    'active-kyc-step': 'linear-gradient(180deg, #DEF7EA , #EAFFF400 )',
    'start-kyc-bg': 'linear-gradient(0deg, #F7F7FD 0%, #E4E3F7 100%)',
    'start-kyc-border': 'linear-gradient(180deg, #E9E8FF 0%, #DCF4E8 100%)',
    'golden-horizontal': 'linear-gradient(90deg, #F0D094, #F3E2C2 50%,#F0D094 100%)',

};
