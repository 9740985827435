/* IMPORTANT: Do not add new enums to this file. Add them in helpers/enums folder **/

const brandNames = Object.freeze({
    ALT_PACK: 'altPack',
    ALT_BLU: 'altBlu',
    ALT_SMART: 'altSmart',
    ALT_ARMOUR: 'altArmour',
    ALT_WINGS: 'altWings',
});

const opportunityStates = Object.freeze({
    PREPAID_AND_CLOSED: 'PrepaidAndClosed',
    MATURED_AND_CLOSED: 'MaturedAndClosed',
    IN_DRAFT: 'InDraft',
});

const securityTransferRecordStatus = Object.freeze({
    PENDING: 'Pending',
    SUCCESS: 'Success',
});

const investmentStatusEnums = Object.freeze({
    FULLY_REPAID: 'Fully Repaid',
    INACTIVE: 'Inactive',
});

export {
    brandNames,
    opportunityStates,
    securityTransferRecordStatus,
    investmentStatusEnums,
};

/* IMPORTANT: Do not add new enums to this file. Add them in helpers/enums folder **/